import axios from 'axios'

export const GOOGLE_GEOCODE_URL =
  'https://maps.googleapis.com/maps/api/geocode/json'
export const NOMINATIME_URL_REVERSE = `${process.env.REACT_APP_NOMINATIM_URL}/reverse`

export const forward_geocode = (userInput) =>
  axios.get(GOOGLE_GEOCODE_URL, {
    params: {
      address: userInput,
      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    },
  })

/*export const reverse_geocode = (lat, lon) =>
  axios.get(GOOGLE_GEOCODE_URL, {
    params: {
      latlng: `${lat},${lon}`,
      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    },
  })*/
export const reverse_geocode = async (lon, lat) => {
  const result = await axios.get(NOMINATIME_URL_REVERSE, {
    params: {
      lon: lon,
      lat: lat,
      format: 'json',
    },
  })

  const final = {
    data: {
      results: [
        {
          formatted_address: result.data.display_name
            ? result.data.display_name
            : [lat, lon].toString(),
          geometry: {
            location: {
              lat,
              lng: lon,
            },
          },
        },
      ],
      status: 'OK',
    },
  }

  return final
}

export const parseGeocodeResponse = (results, lngLat) => {
  if (results.status === 'ZERO_RESULTS') {
    return [
      {
        title: lngLat.toString(),
        description: '',
        selected: true,
        addresslnglat: '',
        sourcelnglat: lngLat,
        displaylnglat: lngLat,
        key: 0,
        addressindex: 0,
      },
    ]
  }

  const processedResults = results.results.map((result, index) => ({
    title: result.formatted_address,
    description: `https://maps.google.com/?q=${result.geometry.location.lat},${result.geometry.location.lng}`,
    selected: false,
    addresslnglat: [result.geometry.location.lng, result.geometry.location.lat],
    sourcelnglat: lngLat || [
      result.geometry.location.lng,
      result.geometry.location.lat,
    ],
    displaylnglat: lngLat || [
      result.geometry.location.lng,
      result.geometry.location.lat,
    ],
    key: index,
    addressindex: index,
  }))

  return processedResults
}
